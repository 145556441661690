import axios from 'axios'
import { useState } from 'react'
import ErrorAlert from './ErrorAlert'
import style from './includes/css/kunde.module.css'
import LoadingSpinner from './LoadingSpinner'
import SuccessAlert from './SuccessAlert'

const Kunde = props => {

    const {kunde, serviceBerater, LoadingState} = props 
    
    const [inputMessage, setInputMessage] = useState('')
    const [mailLoading, setMailLoading] = useState(LoadingState.NotStartet)

    const bildstrg = serviceBerater.id ? './berater/' + serviceBerater.id + '.jpg' : './berater/platzhalter.jpg'

    const sendMail = () =>{
        if(!inputMessage) {
          alert("Bitte geben Sie einen Text ein.")
          return
        }
    
        setMailLoading(LoadingState.Startet)
    
        const obj = {
          kunde: kunde,
          berater: serviceBerater,
          message: inputMessage
        }
    
        axios.post(process.env.REACT_APP_BASE_URL + '/controllers/mail/send.php', obj)
          .then(() => {
            setMailLoading(LoadingState.Success)
            setInputMessage('')
          })
          .catch(error => {
            setMailLoading(LoadingState.Error)
            console.log(error)
          })
      }

    const getGenderedAnrede = (anrede, name, vorname) => {
      if(anrede === "Herr") return "Sehr geehrter Herr " + name
      if(anrede === "Frau") return "Sehr geehrte Frau " + name
      if(anrede === "Divers") return "Hallo " + vorname + " " + name
    }
    

    return <>
        <h1 style={{fontSize:'38px', fontWeight:'bold',color:'#00AEEF'}} className="mt-2">Auftragsstatus</h1>
            <div >
                <p className="mt-3 mb-3">{getGenderedAnrede(kunde.anrede, kunde.name, kunde.vorname)},</p>
                { 
                  kunde.kennzeichen === '' ? 
                    <p>Ihr Fahrzeug ist {kunde.status}.</p> : 
                    <p>Ihr Fahrzeug mit dem Kennzeichen {kunde.kennzeichen} ist {kunde.status}.</p>
                }
                <p className="mt-4" style={{fontWeight:'bold'}}>Ihr Wackenhut-Team</p>
            </div>
                <br />
                {serviceBerater ?
                <>
                <h3 style={{fontWeight:'bold',color:'#00AEEF'}}>IHR SERVICEBERATER</h3>
                <div className="row mb-5">
                    <div className="col-md-2" >
                        <img className={`${style.ServiceberaterImg}`} src={bildstrg} 
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src="./berater/platzhalter.jpg";
                          }} alt='Bild Service-Berater'
                        />
                    </div>
                    <div className="col-md-7">
                        <p style={{margin:0,fontWeight:'bold'}}>{serviceBerater.vorname} {serviceBerater.name}</p>
                        <p style={{margin:0}}>Serviceberater</p>
                        <p style={{margin:0}}>Tel: {serviceBerater.telefon}</p>
                        <p style={{margin:0}}>Mail: <span>{serviceBerater.email}</span></p>
                    </div>
                </div></> : null}
                <div className="mb-5">
                    <h3 style={{fontWeight:'bold',color:'#00AEEF'}}>IHRE NACHRICHT</h3>
                        <textarea type="text" className="form-control mb-2" rows="6" value={inputMessage} onChange={e => setInputMessage(e.target.value)}/>
                    <button className="btn btn-primary" onClick={sendMail}>Absenden</button>
                    <br />
                    {mailLoading === LoadingState.Startet && <LoadingSpinner />}
                    {mailLoading === LoadingState.Success && <SuccessAlert message={'Ihre Nachricht wurde versendet. Ein Wackenhut-Mitarbeiter wird sich bei Ihnen melden.'}/>}
                    {mailLoading === LoadingState.Error && <ErrorAlert message={'Ihre Nachricht konnte nicht verschickt werden. Kontaktieren Sie wenn möglich das Wackenhut-Team.'}/>}
                </div>
            </>
}

export default Kunde