import React from 'react'
import DigitalSolutions from './includes/img/Logo Wackenhut Digital Solutions weiß.png'
import style from './includes/css/footer.module.css'

const Footer = () => {
  
  return (
    <>
            <div className={`row mt-4 flex-column flex-sm-row ${style.row}`} >
                <div className="col-sm-12 col-md">
                        <span className={`${style.followus}`} style={{cursor:'default'}}> Follow us! </span>
                        
                        <a href="https://www.youtube.com/@WACKENHUT"><img className={`${style.ytIcon}`} src="https://auftragstatus.wackenhut4.me/email_images/youtube.png" alt="youtube"/></a>
                        <a href="https://www.tiktok.com/@wackenhut_"><img className={`${style.icon}`} src="https://auftragstatus.wackenhut4.me/email_images/tiktok.png" alt="tiktok"/></a>
                        <a href="https://www.facebook.com/FirmaWACKENHUT"><img className={`${style.icon}`} src="https://auftragstatus.wackenhut4.me/email_images/facebook.png" alt="facebook"/></a>
                        <a href="https://www.instagram.com/__wackenhut__"><img className={`${style.icon}`} src="https://auftragstatus.wackenhut4.me/email_images/instagram.png" alt="instagram" /></a>
                        <a href="https://www.linkedin.com/company/12975560/admin"><img className={`${style.icon}`} src="https://auftragstatus.wackenhut4.me/email_images/linkedin.png" alt="linkedin"/></a>
                        <a href="https://app.messengerpeople.com/forward?widget=129326100017c9edde7.12538894"><img className={`${style.icon}`} src="https://auftragstatus.wackenhut4.me/email_images/whastapp.png" alt="whatsapp"/></a>
                        <a href="https://www.wackenhut.de/"><img className={`${style.icon}`} src="https://auftragstatus.wackenhut4.me/email_images/webseite.png" alt="webseite"/></a>
                </div>
                <div className="col-sm-12 col-md">
                    <div>
                        <img className={`${style.digitalSolutionsLogo}`} src={DigitalSolutions} alt="Wackenhut Digital Solutions Logo"/>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Footer