
const ErrorAlert = props => {

    const {message} = props
  
    return (
        <>
            <br />
            <div className="alert alert-danger" style={{textAlign: 'center'}}>{message}</div>
        </>
        
    );
}

export default ErrorAlert