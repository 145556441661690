import React from 'react'
import whpd from './includes/img/WHPD_neg.png'
import platzhalterMB from './includes/img/G-Klasse_Werkstatt_RGB.png'
import platzhalterSkoda from './includes/img/ZeichnungSkodaMonteur.png' 
import style from './includes/css/header.module.css'

const Header = props => {

  const {kunde} = props

  const getRightPic = (kunde) => {
    if(kunde && kunde.source === "mb") return <img className="img-fluid" src={platzhalterMB} alt="G-Klasse Linienzeichnung" style={{width: '100%'}}/>
    return <img className="img-fluid" src={platzhalterSkoda} alt="Monteur Linienzeichnung" style={{width: '100%'}}/>
  }

  return (
    <>
        <div className="text-center mt-4">
            <img className={`${style.logo}`} src={whpd} alt="Wackenhut Logo"/>
        </div>
        <br/>
        <br/>
        <div className="text-center mt-5">
            {getRightPic(kunde)}
        </div>
    </>
  )
}

export default Header