import { useEffect, useState } from "react";
import axios from 'axios'
import ErrorAlert from "./ErrorAlert";
import LoadingSpinner from "./LoadingSpinner";
import Kunde from "./Kunde";
import Footer from "./Footer";
import Header from "./Header";

const App = () => {

  const LoadingState = {
    NotStartet: 0,
    Startet: 1,
    Success: 2,
    Error: 3
  }

  const [kunde, setKunde] = useState(null)
  const [loading, setLoading] = useState(LoadingState.NotStartet)
  const [serviceBerater, setServiceBerater] = useState(null)

  useEffect(() => {
    setLoading(LoadingState.Startet)
    const id = window.location.pathname.replace('/', '') // 74bdc65f-1337-450f-a99f-1e55335e2876

    axios.get(process.env.REACT_APP_BASE_URL + '/controllers/kunden/kunden.php?id=' + id)
      .then(response => {
        setKunde(response.data[0])
        axios.get(process.env.REACT_APP_BASE_URL + '/controllers/berater/berater.php?berater_id=' + response.data[0].berater_id)
        .then(response => {
          setServiceBerater(response.data[0])
          setLoading(LoadingState.Success)
        })
        .catch(error => {
          console.log(error)
          setLoading(LoadingState.Success)
        })
      })
      .catch(error => {
        setLoading(LoadingState.Error)
        console.log(error)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  

  return (
    <div >
      <Header kunde={kunde}/>     
      <br />
      {loading === LoadingState.Startet && <LoadingSpinner />}
      <br />
      {loading === LoadingState.Success && kunde && <Kunde kunde={kunde} serviceBerater={serviceBerater} LoadingState={LoadingState}/>}
      {loading === LoadingState.Error && <ErrorAlert message={'Ihre Kundenkennung ist ungültig. Kontaktieren Sie wenn möglich das Wackenhut-Team.'}/>}
      <Footer/>
    </div>
  );
}

export default App;
